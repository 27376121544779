.body-news{
  header{
    .is-news{
      display: block;
      opacity: 1;
      visibility: visible;
      @include mdq(xs-md) {
        display:none;
        opacity:0;
        visibility:hidden;
      }
    }
    .is-filter{
      display:none !important;
      opacity:0 !important;
      visibility:hidden !important;
    }
  }
}
.main-news {
  padding: 200px 0 100px 0;
  @include mdq(xs-md) {
    padding: 100px 0 30px 0;
  }
  .news-component {
    &-mobile-filter {
      display: none;
      opacity: 0;
      visibility: hidden;
      @include mdq(xs-md) {
        display: block;
        visibility: visible;
        opacity: 1;
        margin: 0px 0 40px 0;
      }
      .header-contain-item-filter {
        margin-left: 15px;
        .header-contain-item-nav {
          ul {
            li {
              width: 100%;
              list-style: none;
              margin-bottom: 10px;
              transition: 0.3s ease all;
              color: $color-dark-grey !important;
              @include sohne-buch;
              font-size: 16px;
              line-height: 20px;
              cursor: pointer;
                &:hover {
                  color: $color-dark !important;
                }
                &:focus {
                  color: $color-dark;
                }
              a {
                @include sohne-buch;
                font-size: 16px;
                line-height: 18px;
                color: $color-dark !important;
              }
            }
          }
        }
      }
    }
    .mb-control {
      margin-bottom: 80px;
      @include mdq(xs-md) {
        margin-bottom: 40px;
      }
    }
    .pagination {
      margin-top: 20px;
      margin-bottom: 120px;
      padding: 0 15px;
      @include mdq(xs-md) {
        margin-bottom: 80px;
        margin-top: 10px;
      }
      ul {
        li {
          &:nth-child(5),
          &:nth-child(6) {
            @include mdq(xs) {
              display: none;
            }
          }
        }
      }
    }
    .news-action {
      &-text {
        @include mdq(xs-md) {
          margin-bottom: 40px;
        }
        h2 {
          font-size: 32px !important;
          line-height: 120% !important;
          color: $color-dark;
          @include sohne-leicht;
        }
      }
      &-form {
        .input-type {
          &-group {
            position: relative;
            border-bottom: 1px solid $color-light-grey;
            &:focus-within {
              border-bottom: 1px solid $color-dark;
            }
            &:hover {
              border-bottom: 1px solid $color-dark-grey;
            }
            input {
              width: calc(100% - 68px);
              border-bottom: none !important;
              @include mdq(xs-md) {
                font-size: 18px;
              }
            }
            &-button {
              position: absolute;
              top: 0px;
              right: 0;
              button {
                border: none;
                background: none;
                height: 30px;
                width: 68px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0;
                i {
                  display: flex;
                  svg path {
                    transition: all 400ms ease-in;
                    fill: $color-dark-grey;
                  }
                }
                &:hover {
                  i {
                    display: flex;
                    svg path {
                      fill: $color-dark;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

input,
select,
textarea,
button {
  appearance: none;
}

// input[type='checkbox'] {
//   appearance: auto !important;
// }

.form-group {
  .pagination {
    display: grid;

    ul {
      display: flex;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid $color-light-grey;

      li {
        padding: 6px 8px;
        padding-bottom: 10px;
        margin-left: 20px;
        list-style: none;
        @include mdq(xs-md) {
          margin-left: 15px;
        }

        &:last-child {
          padding-right: 0;
        }

        &:first-child,
        &:last-child {
          &:hover {
            border-bottom: none;
          }
        }

        &.is-active {
          border-bottom: 1px solid $color-dark;

          a {
            color: $color-dark;
          }
        }

        &:hover {
          border-bottom: 1px solid $color-dark;

          a {
            color: $color-dark;

            i svg path {
              fill: $color-dark;
            }
          }
        }

        &.disabled {
          color: $color-dark-grey;
          cursor: not-allowed;
        }

        &:first-child,
        &:last-child {
          flex: 1;
        }

        &:nth-child(2) {
          margin-left: 0;
        }

        &:first-child {
          margin-left: 0;

          i {
            margin-right: 5px;
          }

          &:hover {
            i {
              transform: translateX(-5px);
            }
          }
        }

        &:last-child {
          display: flex;
          justify-content: flex-end;

          i {
            margin-left: 5px;
          }

          &:hover {
            i {
              transform: translateX(5px);
            }
          }
        }

        a {
          @include sohne-buch;
          display: flex;
          align-items: center;
          color: $color-dark-grey;
          font-size: 16px;
          line-height: 1.5;

          span {
            @include mdq(xs-md) {
              display: none;
            }
          }

          i {
            display: flex;
            transition: 0.4s all ease-in;

            svg path {
              fill: $color-dark-grey;
            }
          }
        }
      }
    }
  }

  .input-type {
    .disabled {
      border-bottom: 1px solid $color-light-grey;
      color: $color-light-grey;
      cursor: not-allowed;

      &:hover {
        border-bottom: 1px solid $color-light-grey;
      }

      &:focus-within {
        border-bottom: 1px solid $color-light-grey;
      }
    }

    input {
      @include sohne-leicht;
      width: 100%;
      padding-bottom: 30px;
      border: none;
      border-bottom: 1px solid $color-light-grey;
      color: $color-dark;
      font-size: 24px;
      line-height: 150%;
      @include mdq(xs-md) {
        font-size: 16px;
      }

      &::placeholder {
        color: $color-dark-grey;
      }

      &:focus-within {
        border-bottom-color: $color-dark;
      }

      &:hover {
        border-bottom-color: $color-dark-grey;
      }
    }

    &-error-message {
      margin-top: 20px;
      opacity: 0;
      transition: 0.4s ease-in visibility, 0.4s ease opacity;
      visibility: hidden;

      span {
        @include sohne-leicht;
        color: #f32e2e;
        font-size: 16px;
        line-height: 150%;
      }

      &.is-active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

input {
  @include sohne-leicht;
  width: 100%;
  padding-bottom: 30px !important;
  padding-left: 0 !important;
  border: none !important;
  border-bottom: 1px solid $color-light-grey !important;
  color: $color-dark !important;
  font-size: 24px !important;
  line-height: 150%;
  @include mdq(xs-md) {
    padding-bottom: 15px !important;
    font-size: 16px !important;
  }

  &::placeholder {
    color: $color-dark-grey;
  }

  &:focus-within {
    border-bottom-color: $color-dark;
  }

  &:hover {
    border-bottom-color: $color-dark-grey;
  }
}

.gfield_label {
  display: none !important;
}

.gform-heading {
  display: none;
}

.gform_fileupload_rules {
  @include sohne-leicht;
  color: $color-dark-grey;
}

.gform_button.button {
  @include sohne-leicht;
  display: flex !important;
  width: auto !important;
  flex-shrink: 0 !important;
  align-items: center !important;
  padding: 0 !important;
  padding-bottom: 0 !important;
  border: none !important;
  border-bottom: 1px solid #878a8c !important;
  background-color: #fff !important;
  border-spacing: 1px !important;
  color: #878a8c !important;
  cursor: pointer !important;
  font-size: 24px !important;
  line-height: 150%;
  transition: all 0.3s ease;
}

.ginput_container_fileupload {
  input {
    border-bottom: none !important;
  }
}

.ginput_container {
  margin-bottom: 50px !important;
  @include mdq(xs-md) {
    margin-bottom: 30px !important;
  }
}

.hidden-file-loader {
  display: none !important;
}

.send-button-hidden {
  display: none !important;
}

.validation_message {
  padding-left: 0 !important;
  border: none !important;
  background: none !important;
  color: #f32e2e !important;
  font-size: 22px !important;
  @include mdq(xs-md) {
    font-size: 14px !important;
  }
}

.gform_confirmation_message {
  @include sohne-leicht;
  color: #f32e2e !important;
  font-size: 22px !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
  @include mdq(xs-md) {
    font-size: 16px !important;
  }
}

.gform_wrapper .gform_validation_errors {
  padding: 16px 16px 16px 34px !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;

  h2 {
    @include sohne-leicht;
    padding-left: 0 !important;
    color: #f32e2e !important;
    font-size: 22px !important;
    @include mdq(xs-md) {
      font-size: 16px !important;
    }
  }
}

.gform-icon.gform-icon--close {
  left: 0 !important;
}

.gfield_description {
  @include sohne-leicht;
  color: #f32e2e !important;
  font-size: 22px !important;
  font-weight: 300 !important;
  line-height: 1.5 !important;
  @include mdq(xs-md) {
    font-size: 16px !important;
  }
}

.mc4wp-success,
.mc4wp-error {
  p {
    @include sohne-leicht;
    margin-top: 20px !important;
    font-size: 22px !important;
    font-weight: 300 !important;
    line-height: 1.4 !important;
    @include mdq(xs-md) {
      font-size: 16px !important;
    }
  }
}

.mc4wp-success p {
  color: #08ca55 !important;
}

.mc4wp-error p {
  color: #f32e2e !important;
}

.mc4wp-notice,
.mc4wp-alert {
  p {
    @include sohne-leicht;
    margin-top: 20px !important;
    font-size: 22px !important;
    font-weight: 300 !important;
    line-height: 1.4 !important;
    @include mdq(xs-md) {
      font-size: 16px !important;
    }
  }
}

.mc4wp-notice p {
  color: $color-dark-grey !important;
}

.mc4wp-alert p {
  color: #d64747 !important;
}

.instruction.validation_message {
  @include sohne-leicht;
  margin-top: 10px !important;
  font-size: 22px !important;
  font-weight: 300 !important;
  line-height: 1.4 !important;
  @include mdq(xs-md) {
    font-size: 16px !important;
  }
}

.ginput_container.ginput_container_checkbox {
  .gchoice {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 18px;
    }

    label {
      color: #878a8c;
      font-size: 20px;
      line-height: 150%;
    }
  }

  .gfield-choice-input {
    position: relative;
    width: 24px;
    height: 24px;
    padding-bottom: 0 !important;
    border: 1px solid #e5e5e5 !important;
    margin-right: 15px;

    &:checked::before {
      border: 1px solid #000;
      background: url('../img/checkbox-icon.png');
      background-size: 100% 100%;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background: transparent;
      content: '';
    }
  }
}

.owl-dots {
  position: absolute;
  bottom: 0;
  display: flex;
  width: fit-content !important;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 100px 0 50px;
  padding-left: 50px;
  margin-top: 0 !important;
  background-color: #fff;
  @include mdq(xs-md) {
    padding: 25px 10px 25px 25px;
  }

  .owl-dot {
    width: fit-content !important;

    &:last-child {
      span {
        margin-right: 0 !important;
      }
    }

    span {
      width: 8px !important;
      height: 8px !important;
      margin: 0 !important;
      margin-right: 45px !important;
      background-color: #cccbca !important;
      border-radius: 50% !important;
      @include mdq(xs-md) {
        width: 4px !important;
        height: 4px !important;
        margin-right: 15px !important;
      }
    }

    &:hover {
      span {
        background: #000 !important;
      }
    }
  }

  .active {
    span {
      background: #000 !important;
    }
  }
}

.owl-next,
.owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @include mdq(xs) {
    top: 80px;
    transform: none;
  }
  @include mdq(md) {
    top: 230px;
    transform: none;
  }
  @include mdq(xs-md) {
    width: 40px;
    height: 40px;
  }
}

.owl-next {
  right: 50px;
  @include mdq(xs-md) {
    right: 20px;
  }

  svg {
    @include mdq(xs-md) {
      width: 40px;
      height: 40px;
    }
  }
}

.owl-prev {
  left: 50px;
  @include mdq(xs-md) {
    left: 20px;
  }

  svg {
    @include mdq(xs-md) {
      width: 40px;
      height: 40px;
    }
  }
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: transparent;
}

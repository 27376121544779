@font-face {
  font-display: swap;
  font-family: 'Sohne Buch';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Sohne-Buch.woff2') format('woff2'), url('../fonts/Sohne-Buch.woff') format('woff');
}
@mixin sohne-buch {
  font-family: 'Sohne Buch';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Dreiviertelfett';
  font-style: normal;
  font-weight: bold;
  src:
    url('../fonts/Sohne-Dreiviertelfett.woff2') format('woff2'),
    url('../fonts/Sohne-Dreiviertelfett.woff') format('woff');
}
@mixin sohne-dreiviertelfett {
  font-family: 'Sohne Dreiviertelfett';
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Buch Kursiv';
  font-style: italic;
  font-weight: normal;
  src: url('../fonts/Sohne-BuchKursiv.woff2') format('woff2'), url('../fonts/Sohne-BuchKursiv.woff') format('woff');
}
@mixin sohne-buch-kursiv {
  font-family: 'Sohne Buch Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Fett';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Sohne-Fett.woff2') format('woff2'), url('../fonts/Sohne-Fett.woff') format('woff');
}
@mixin sohne-fett {
  font-family: 'Sohne Fett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
  src:
    url('../fonts/Sohne-ExtrafettKursiv.woff2') format('woff2'),
    url('../fonts/Sohne-ExtrafettKursiv.woff') format('woff');
}
@mixin sohne-extrafett-kursiv {
  font-family: 'Sohne Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Extraleicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src:
    url('../fonts/Sohne-ExtraleichtKursiv.woff2') format('woff2'),
    url('../fonts/Sohne-ExtraleichtKursiv.woff') format('woff');
}
@mixin sohne-extraleicht-kursiv {
  font-family: 'Sohne Extraleicht Kursiv ';
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Dreiviertelfett Kursiv';
  font-style: italic;
  font-weight: bold;
  src:
    url('../fonts/Sohne-DreiviertelfettKursiv.woff2') format('woff2'),
    url('../fonts/Sohne-DreiviertelfettKursiv.woff') format('woff');
}
@mixin sohne-dreiviertelfett-kursiv {
  font-family: 'Sohne Dreiviertelfett Kursiv';
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Extrafett';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Sohne-Extrafett.woff2') format('woff2'), url('../fonts/Sohne-Extrafett.woff') format('woff');
}
@mixin sohne-extrafett {
  font-family: 'Sohne Extrafett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Extraleicht';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Sohne-Extraleicht.woff2') format('woff2'), url('../fonts/Sohne-Extraleicht.woff') format('woff');
}
@mixin sohne-extraleicht {
  font-family: 'Sohne Extraleicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/Sohne-HalbfettKursiv.woff2') format('woff2'),
    url('../fonts/Sohne-HalbfettKursiv.woff') format('woff');
}
@mixin sohne-halbfett-kursiv {
  font-family: 'Sohne Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Halbfett';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Sohne-Halbfett.woff2') format('woff2'), url('../fonts/Sohne-Halbfett.woff') format('woff');
}
@mixin sohne-halbfett {
  font-family: 'Sohne Halbfett';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Fett Kursiv';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/Sohne-FettKursiv.woff2') format('woff2'), url('../fonts/Sohne-FettKursiv.woff') format('woff');
}
@mixin sohne-fett-kursiv {
  font-family: 'Sohne Fett Kursiv';
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Kraftig';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Sohne-Kraftig.woff2') format('woff2'), url('../fonts/Sohne-Kraftig.woff') format('woff');
}
@mixin sohne-kraftig {
  font-family: 'Sohne Kraftig';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
  src:
    url('../fonts/Sohne-KraftigKursiv.woff2') format('woff2'),
    url('../fonts/Sohne-KraftigKursiv.woff') format('woff');
}
@mixin sohne-kraftig-kursiv {
  font-family: 'Sohne Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Leicht';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Sohne-Leicht.woff2') format('woff2'), url('../fonts/Sohne-Leicht.woff') format('woff');
}
@mixin sohne-leicht {
  font-family: 'Sohne Leicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/Sohne-LeichtKursiv.woff2') format('woff2'), url('../fonts/Sohne-LeichtKursiv.woff') format('woff');
}
@mixin sohne-leicht-kursiv {
  font-family: 'Sohne Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Buch Kursiv';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/SohneBreit-BuchKursiv.woff2') format('woff2'),
    url('../fonts/SohneBreit-BuchKursiv.woff') format('woff');
}
@mixin sohne-breit-buch-kursiv {
  font-family: 'Sohne Breit Buch Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Buch';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/SohneBreit-Buch.woff2') format('woff2'), url('../fonts/SohneBreit-Buch.woff') format('woff');
}
@mixin sohne-breit-buch {
  font-family: 'Sohne Breit Buch';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Dreiviertelfett Kurs';
  font-style: italic;
  font-weight: bold;
  src:
    url('../fonts/SohneBreit-DreiviertelfettKurs.woff2') format('woff2'),
    url('../fonts/SohneBreit-DreiviertelfettKurs.woff') format('woff');
}
@mixin sohne-breit-dreiviertelfett-kurs {
  font-family: 'Sohne Breit Dreiviertelfett Kurs';
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Extrafett';
  font-style: normal;
  font-weight: 900;
  src:
    url('../fonts/SohneBreit-Extrafett.woff2') format('woff2'),
    url('../fonts/SohneBreit-Extrafett.woff') format('woff');
}
@mixin sohne-breit-extrafett {
  font-family: 'Sohne Breit Extrafett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Dreiviertelfett';
  font-style: normal;
  font-weight: bold;
  src:
    url('../fonts/SohneBreit-Dreiviertelfett.woff2') format('woff2'),
    url('../fonts/SohneBreit-Dreiviertelfett.woff') format('woff');
}
@mixin sohne-breit-dreiviertelfett {
  font-family: 'Sohne Breit Dreiviertelfett';
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
  src:
    url('../fonts/SohneBreit-ExtrafettKursiv.woff2') format('woff2'),
    url('../fonts/SohneBreit-ExtrafettKursiv.woff') format('woff');
}
@mixin sohne-breit-extrafett-kursiv {
  font-family: 'Sohne Breit Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Extraleicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src:
    url('../fonts/SohneBreit-ExtraleichtKursiv.woff2') format('woff2'),
    url('../fonts/SohneBreit-ExtraleichtKursiv.woff') format('woff');
}
@mixin sohne-breit-extraleicht-kursiv {
  font-family: 'Sohne Breit Extraleicht Kursiv';
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Extraleicht';
  font-style: normal;
  font-weight: 300;
  src:
    url('../fonts/SohneBreit-Extraleicht.woff2') format('woff2'),
    url('../fonts/SohneBreit-Extraleicht.woff') format('woff');
}
@mixin sohne-breit-extraleicht {
  font-family: 'Sohne Breit Extraleicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Fett Kursiv';
  font-style: italic;
  font-weight: 900;
  src:
    url('../fonts/SohneBreit-FettKursiv.woff2') format('woff2'),
    url('../fonts/SohneBreit-FettKursiv.woff') format('woff');
}
@mixin sohne-breit-fett-kursiv {
  font-family: 'Sohne Breit Fett Kursiv';
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Fett';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/SohneBreit-Fett.woff2') format('woff2'), url('../fonts/SohneBreit-Fett.woff') format('woff');
}
@mixin sohne-breit-fett {
  font-family: 'Sohne Breit Fett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Halbfett';
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/SohneBreit-Halbfett.woff2') format('woff2'),
    url('../fonts/SohneBreit-Halbfett.woff') format('woff');
}
@mixin sohne-breit-halbfett {
  font-family: 'Sohne Breit Halbfett';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/SohneBreit-HalbfettKursiv.woff2') format('woff2'),
    url('../fonts/SohneBreit-HalbfettKursiv.woff') format('woff');
}
@mixin sohne-breit-halbfett-kursiv {
  font-family: 'Sohne Breit Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
  src:
    url('../fonts/SohneBreit-KraftigKursiv.woff2') format('woff2'),
    url('../fonts/SohneBreit-KraftigKursiv.woff') format('woff');
}
@mixin sohne-breit-kraftig-kursiv {
  font-family: 'Sohne Breit Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Kraftig';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/SohneBreit-Kraftig.woff2') format('woff2'), url('../fonts/SohneBreit-Kraftig.woff') format('woff');
}
@mixin sohne-breit-kraftig {
  font-family: 'Sohne Breit Kraftig';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Leicht';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/SohneBreit-Leicht.woff2') format('woff2'), url('../fonts/SohneBreit-Leicht.woff') format('woff');
}
@mixin sohne-breit-leicht {
  font-family: 'Sohne Breit Leicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Dreiviertelfett';
  font-style: normal;
  font-weight: bold;
  src:
    url('../fonts/SohneMono-Dreiviertelfett.woff2') format('woff2'),
    url('../fonts/SohneMono-Dreiviertelfett.woff') format('woff');
}
@mixin sohne-mono-dreiviertelfett {
  font-family: 'Sohne Mono Dreiviertelfett';
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Buch';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/SohneMono-Buch.woff2') format('woff2'), url('../fonts/SohneMono-Buch.woff') format('woff');
}
@mixin sohne-mono-buch {
  font-family: 'Sohne Mono Buch';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Extraleicht';
  font-style: normal;
  font-weight: 300;
  src:
    url('../fonts/SohneMono-Extraleicht.woff2') format('woff2'),
    url('../fonts/SohneMono-Extraleicht.woff') format('woff');
}
@mixin sohne-mono-extraleicht {
  font-family: 'Sohne Mono Extraleicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Breit Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src:
    url('../fonts/SohneBreit-LeichtKursiv.woff2') format('woff2'),
    url('../fonts/SohneBreit-LeichtKursiv.woff') format('woff');
}
@mixin sohne-breit-leicht-kursiv {
  font-family: 'Sohne Breit Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Buch Kursiv';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/SohneMono-BuchKursiv.woff2') format('woff2'),
    url('../fonts/SohneMono-BuchKursiv.woff') format('woff');
}
@mixin sohne-mono-buch-kursiv {
  font-family: 'Sohne Mono Buch Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Extrafett';
  font-style: normal;
  font-weight: 900;
  src:
    url('../fonts/SohneMono-Extrafett.woff2') format('woff2'),
    url('../fonts/SohneMono-Extrafett.woff') format('woff');
}
@mixin sohne-mono-extrafett {
  font-family: 'Sohne Mono Extrafett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
  src:
    url('../fonts/SohneMono-ExtrafettKursiv.woff2') format('woff2'),
    url('../fonts/SohneMono-ExtrafettKursiv.woff') format('woff');
}
@mixin sohne-mono-extrafett-kursiv {
  font-family: 'Sohne Mono Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Extraleicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src:
    url('../fonts/SohneMono-ExtraleichtKursiv.woff2') format('woff2'),
    url('../fonts/SohneMono-ExtraleichtKursiv.woff') format('woff');
}
@mixin sohne-mono-extraleicht-kursiv {
  font-family: 'Sohne Mono Extraleicht Kursiv';
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Dreiviertelfett Kurs';
  font-style: italic;
  font-weight: bold;
  src:
    url('../fonts/SohneMono-DreiviertelfettKurs.woff2') format('woff2'),
    url('../fonts/SohneMono-DreiviertelfettKurs.woff') format('woff');
}
@mixin sohne-mono-dreiviertelfett-kurs {
  font-family: 'Sohne Mono Dreiviertelfett Kurs';
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Fett Kursiv';
  font-style: italic;
  font-weight: 900;
  src:
    url('../fonts/SohneMono-FettKursiv.woff2') format('woff2'),
    url('../fonts/SohneMono-FettKursiv.woff') format('woff');
}
@mixin sohne-mono-fett-kursiv {
  font-family: 'Sohne Mono Fett Kursiv';
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Kraftig';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/SohneMono-Kraftig.woff2') format('woff2'), url('../fonts/SohneMono-Kraftig.woff') format('woff');
}
@mixin sohne-mono-kraftig {
  font-family: 'Sohne Mono Kraftig';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Fett';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/SohneMono-Fett.woff2') format('woff2'), url('../fonts/SohneMono-Fett.woff') format('woff');
}
@mixin sohne-mono-fett {
  font-family: 'Sohne Mono Fett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Leicht';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/SohneMono-Leicht.woff2') format('woff2'), url('../fonts/SohneMono-Leicht.woff') format('woff');
}
@mixin sohne-mono-leicht {
  font-family: 'Sohne Mono Leicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
  src:
    url('../fonts/SohneMono-KraftigKursiv.woff2') format('woff2'),
    url('../fonts/SohneMono-KraftigKursiv.woff') format('woff');
}
@mixin sohne-mono-kraftig-kursiv {
  font-family: 'Sohne Mono Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Halbfett';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/SohneMono-Halbfett.woff2') format('woff2'), url('../fonts/SohneMono-Halbfett.woff') format('woff');
}
@mixin sohne-mono-halbfett {
  font-family: 'Sohne Mono Halbfett';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src:
    url('../fonts/SohneMono-LeichtKursiv.woff2') format('woff2'),
    url('../fonts/SohneMono-LeichtKursiv.woff') format('woff');
}
@mixin sohne-mono-leicht-kursiv {
  font-family: 'Sohne Mono Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Mono Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/SohneMono-HalbfettKursiv.woff2') format('woff2'),
    url('../fonts/SohneMono-HalbfettKursiv.woff') format('woff');
}
@mixin sohne-mono-halbfett-kursiv {
  font-family: 'Sohne Mono Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Extrafett';
  font-style: normal;
  font-weight: 900;
  src:
    url('../fonts/SohneSchmal-Extrafett.woff2') format('woff2'),
    url('../fonts/SohneSchmal-Extrafett.woff') format('woff');
}
@mixin sohne-schmal-extrafett {
  font-family: 'Sohne Schmal Extrafett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Buch Kursiv';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/SohneSchmal-BuchKursiv.woff2') format('woff2'),
    url('../fonts/SohneSchmal-BuchKursiv.woff') format('woff');
}
@mixin sohne-schmal-buch-kursiv {
  font-family: 'Sohne Schmal Buch Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Dreiviertelfett Kurs';
  font-style: italic;
  font-weight: bold;
  src:
    url('../fonts/SohneSchmal-DreiviertelfettKurs.woff2') format('woff2'),
    url('../fonts/SohneSchmal-DreiviertelfettKurs.woff') format('woff');
}
@mixin sohne-schmal-dreiviertelfett-kurs {
  font-family: 'Sohne Schmal Dreiviertelfett Kurs';
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Buch';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/SohneSchmal-Buch.woff2') format('woff2'), url('../fonts/SohneSchmal-Buch.woff') format('woff');
}
@mixin sohne-schmal-buch {
  font-family: 'Sohne Schmal Buch';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Dreiviertelfett';
  font-style: normal;
  font-weight: bold;
  src:
    url('../fonts/SohneSchmal-Dreiviertelfett.woff2') format('woff2'),
    url('../fonts/SohneSchmal-Dreiviertelfett.woff') format('woff');
}
@mixin sohne-schmal-dreiviertelfett {
  font-family: Sohne Schmal Dreiviertelfett;
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
  src:
    url('../fonts/SohneSchmal-ExtrafettKursiv.woff2') format('woff2'),
    url('../fonts/SohneSchmal-ExtrafettKursiv.woff') format('woff');
}
@mixin sohne-schmal-extrafett-kursiv {
  font-family: 'Sohne Schmal Extrafett Kursiv';
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Fett Kursiv';
  font-style: italic;
  font-weight: 900;
  src:
    url('../fonts/SohneSchmal-FettKursiv.woff2') format('woff2'),
    url('../fonts/SohneSchmal-FettKursiv.woff') format('woff');
}
@mixin sohne-schmal-fett-kursiv {
  font-family: 'Sohne Schmal Fett Kursiv';
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Fett';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/SohneSchmal-Fett.woff2') format('woff2'), url('../fonts/SohneSchmal-Fett.woff') format('woff');
}
@mixin sohne-schmal-fett {
  font-family: 'Sohne Schmal Fett';
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Extraleicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src:
    url('../fonts/SohneSchmal-ExtraleichtKursiv.woff2') format('woff2'),
    url('../fonts/SohneSchmal-ExtraleichtKursiv.woff') format('woff');
}
@mixin sohne-schmal-extraleicht-kursiv {
  font-family: 'Sohne Schmal Extraleicht Kursiv';
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Extraleicht';
  font-style: normal;
  font-weight: 300;
  src:
    url('../fonts/SohneSchmal-Extraleicht.woff2') format('woff2'),
    url('../fonts/SohneSchmal-Extraleicht.woff') format('woff');
}
@mixin sohne-schmal-extraleicht {
  font-family: 'Sohne Schmal Extraleicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Halbfett';
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/SohneSchmal-Halbfett.woff2') format('woff2'),
    url('../fonts/SohneSchmal-Halbfett.woff') format('woff');
}
@mixin sohne-schmal-halbfett {
  font-family: 'Sohne Schmal Halbfett';
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
  src:
    url('../fonts/SohneSchmal-HalbfettKursiv.woff2') format('woff2'),
    url('../fonts/SohneSchmal-HalbfettKursiv.woff') format('woff');
}
@mixin sohne-schmal-halbfett-kursiv {
  font-family: 'Sohne Schmal Halbfett Kursiv';
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
  src:
    url('../fonts/SohneSchmal-KraftigKursiv.woff2') format('woff2'),
    url('../fonts/SohneSchmal-KraftigKursiv.woff') format('woff');
}
@mixin sohne-schmal-kraftig-kursiv {
  font-family: 'Sohne Schmal Kraftig Kursiv';
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Kraftig';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/SohneSchmal-Kraftig.woff2') format('woff2'),
    url('../fonts/SohneSchmal-Kraftig.woff') format('woff');
}
@mixin sohne-schmal-kraftig {
  font-family: 'Sohne Schmal Kraftig';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Leicht';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/SohneSchmal-Leicht.woff2') format('woff2'), url('../fonts/SohneSchmal-Leicht.woff') format('woff');
}
@mixin sohne-schmal-leicht {
  font-family: 'Sohne Schmal Leicht';
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: 'Sohne Schmal Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
  src:
    url('../fonts/SohneSchmal-LeichtKursiv.woff2') format('woff2'),
    url('../fonts/SohneSchmal-LeichtKursiv.woff') format('woff');
}
@mixin sohne-schmal-leicht-kursiv {
  font-family: 'Sohne Schmal Leicht Kursiv';
  font-style: italic;
  font-weight: 300;
}

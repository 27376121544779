header {
  z-index: 5;
  width: 100%;
  background: #ffffff;
  position: absolute;
  top: 0;

  .container-fluid {
    padding: 0 50px !important;
    @include mdq(xs-md) {
      padding: 0 5px !important;
    }
  }
  .row {
    width: 100%;
    @include mdq(xs-md) {
      width: unset;
      align-items: center;
    }
  }
  &.is-open {
    background: #ffffff !important;
    .header-contain-item-mobile {
      display: block !important;
      visibility: visible !important;
      opacity: 1 !important;
    }
    .header-contain-item {
      &-mobile-actions {
        button {
          width: 28px;
          height: 28px;
          span {
            top: 50% !important;
            width: 18px;

            &:nth-child(1) {
              right: 8px;
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      &.is-mobile {
        @include mdq(xs-md) {
          width: 100%;
          position: absolute;
          top: 82px;
          background: #fff;
          left: 0px;
          height: calc(100vh - 82px);
          transform: translateX(0);
          z-index: 39;
        }
        .nav-menu-address {
          display: block;
          position: absolute;
          bottom: 30px;
          a {
            font-size: 16px;
            line-height: 150%;
            @include sohne-buch;
            color: #878a8c;
          }
        }
        .header-contain-item-nav {
          &.nav-menu {
            transform: translateX(0);
            width: 100%;
            padding: 0 5px 0 20px;
            height: 100%;
            ul {
              display: block !important;
              width: 100%;
              li {
                .filter-change-item {
                  display: none;
                }
                ul {
                  display: flex;
                  visibility: visible;
                  opacity: 1;
                  li a {
                    display: flex;
                    font-size: 20px;
                    line-height: 1.5;
                    width: 100%;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #e5e5e5;
                    &::after {
                      content: none;
                    }
                  }
                }
              }
            }
          }
        }
        .row {
          @include mdq(xs-md) {
            width: 100%;
            height: 100%;
          }
          .col-lg-4 {
            @include mdq(xs-md) {
              height: 100%;
            }
          }
        }
      }
    }
  }
  .header-contain {
    display: flex;
    height: auto;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.5s all ease;
    width: 100%;
    gap: 30px;
    padding: 40px 0px;
    @include mdq(xs-md) {
      padding: 26px 16px;
      align-items: unset;
    }
    .header-contain-item-mobile-actions {
      display: none;
      align-items: center;
      @include mdq(xs-md) {
        display: flex;
      }
      button {
        position: relative;
        display: flex;
        width: 28px;
        height: 22px;
        padding: 0;
        border: none;
        background-color: transparent;
        span {
          position: absolute;
          left: 0;
          display: flex;
          width: 100%;
          height: 2px;
          background: #000;
          transition: 0.5s all ease;
          &:first-child {
            top: 5px;
          }
          &:nth-child(2) {
            top: 15px;
          }
        }
      }
    }
    &-item {
      display: flex;
      height: 100%;
      align-items: flex-start;
      width: 50%;

      .is-news {
        display: none;
        opacity: 0;
        visibility: hidden;
        .header-contain-item-nav ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          li {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }

      &.is-mobile {
        margin-top: 7px;
        transition: 0.6s all ease-in;
        @include mdq(xs-md) {
          margin-top: 0;
          transform: translateX(-100%);
        }
      }
      .row {
        justify-content: space-between;
        .col-lg-4 {
          &:first-child {
            padding-left: 0 !important;
          }
          &:last-child {
            padding-right: 0 !important;
          }
        }
      }
      .is-about,
      .is-filter {
        display: none;
        padding-left: 12px;
      }
      .is-about {
        .header-contain-item-nav {
          ul > li > a {
            position: relative;
            &:hover {
              &::after {
                width: 100%;
              }
            }
            &::after {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0;
              height: 1px;
              background-color: $color-dark;
              content: "";
              transition: 0.5s width ease;
            }
          }
        }

        .header-contain-item-nav {
          transition: 0.5s all ease;
          ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            li {
              margin-bottom: 10px;
              ul li {
                a {
                  position: relative;
                  &:hover {
                    &::after {
                      width: 100%;
                    }
                  }
                  &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: $color-dark;
                    content: "";
                    transition: 0.5s width ease;
                  }
                }
              }
            }
          }
        }
      }
      .is-filter {
        ul li ul {
          padding-right: 15px;
          li a {
            color: $color-dark-grey;
            &::after {
              content: none;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          ul li ul li {
            &:hover {
              transform: translateX(0);
            }
          }
        }
      }

      &-filter {
        display: flex;
        @include mdq(lg) {
          justify-content: center;
        }
        .header-contain-item-nav {
          width: 50%;
          &:first-child {
            margin-right: 30px;
          }
        }
        .header-contain-item-nav {
          ul {
            li {
              ul li {
                a {
                  width: 100%;
                  color: $color-dark-grey !important;
                  @include sohne-buch;
                  font-size: 18px;
                  line-height: 1;
                  cursor: pointer;
                  &:hover {
                    color: $color-dark !important;
                    transform: translateX(0);
                  }
                  &:focus {
                    color: $color-dark;
                  }
                }
              }
            }
          }
        }
      }
      &-logo {
        width: 100px;
        height: 32px;
        @include mdq(xs-md) {
          height: 28px;
          width: 89px;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .nav-menu-address {
        display: none;
      }
      &-nav {
        ul {
          position: relative;
          display: flex;
          align-items: center;

          @include mdq(xs-md) {
            display: none;
            width: 100%;
            flex-direction: column;
          }
          li {
            list-style: none;
          }
          a {
            white-space: nowrap;
            @include sohne-buch;
            font-size: 18px;
            line-height: 1;
            color: $color-dark;
            transition: all 0.5s ease-in;
            display: flex;
            align-items: flex-end;
            position: relative;

            svg {
              margin-left: 7px;
              transition: all 0.5s ease;
              transform: rotate(0deg);
              width: 12px;
              height: 14px;
            }
          }

          ul {
            position: absolute;
            z-index: 5;
            top: calc(100% - 5px);
            display: flex;
            min-width: 100%;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 15px;
            border-radius: 10px;
            opacity: 0;
            visibility: hidden;
            width: 54px;
            min-width: 160px;
            @include mdq(xs-md) {
              position: relative;
              top: unset;
              display: none;
            }
            li {
              padding: 0;
              margin-right: 0;
              width: auto;
              list-style: none;
              margin-bottom: 15px;

              a {
                @include sohne-buch;
                font-size: 18px;
                line-height: 1;
                color: $color-dark !important;
                &:hover {
                  &::after {
                    width: 100%;
                  }
                }
                &::after {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 0;
                  height: 1px;
                  background-color: $color-dark;
                  content: "";
                  transition: 0.5s width ease;
                }
              }
            }
          }
        }
      }
      &-action {
        display: flex;
        justify-content: flex-end;
        @include mdq(xs-md) {
          display: none;
        }
        ul {
          li {
            list-style: none;

            a {
              @include sohne-buch;
              font-size: 18px;
              line-height: 18px;
              color: $color-dark;
              position: relative;
              &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background-color: $color-dark;
                content: "";
                transition: 0.5s width ease;
              }
              &:hover {
                &::after {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sm-md-overflow-none {
  @include mdq(xs-md) {
    overflow: hidden;
  }
}
.filter-active-item {
  color: $color-dark-grey !important;
  svg {
    transform: rotate(-180deg) !important;
    path {
      fill: $color-dark-grey;
    }
  }
}
.filter-active {
  opacity: 1 !important;
  visibility: visible !important;
  position: relative !important;
}
.menu-active-item {
  span {
    color: $color-dark-grey;
  }
  svg {
    transform: rotate(-180deg) !important;
    path {
      fill: $color-dark-grey;
    }
  }
}
.menu-active {
  opacity: 1 !important;
  visibility: visible !important;
  position: relative !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

.plyr {
  .plyr__control.plyr__control--overlaid {
    background-color: transparent !important;
    width: 50px;
    height: 50px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .plyr__controls {
    display: none;
  }
}
.plyr__control.plyr__control--overlaid {
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: url(../img/icon-play.svg);
    width: 50px;
    height: 50px;
  }
  svg {
    display: none;
  }
}

.main-apply-form {
  padding: 200px 0 160px 0;
  @include mdq(xs-md) {
    padding: 120px 0 80px 0;
  }
}
.apply {
  &-head {
    @include mdq(xs-md) {
      margin-bottom: 35px;
    }
    h2 {
      font-size: 32px;
      line-height: 36px;
      color: $color-dark;
      @include sohne-leicht;
      @include mdq(xs-md) {
        font-size: 24px;
      }
    }
  }
  &-now {
    margin-bottom: 150px;
    @include mdq(xs-md) {
      margin-bottom: 60px;
    }
    &-content {
      &-item {
        margin-bottom: 30px;
        @include mdq(xs-md) {
          margin-bottom: 20px;
        }
        &:first-child {
          p {
            margin-bottom: 15px;
            @include mdq(xs-md) {
              margin-bottom: 10px;
            }
          }
        }
        li {
          list-style: inside;
          padding-left: 15px;
          @include mdq(xs-md) {
            padding-left: 10px;
          }
        }
        h4 {
          margin-bottom: 15px;
          @include mdq(xs-md) {
            margin-bottom: 10px;
          }
        }
        p,
        span,
        h4,
        li {
          font-size: 24px;
          line-height: 150%;
          @include sohne-leicht;
          color: $color-dark-grey;
          @include mdq(xs-md) {
            font-size: 20px;
          }
        }
      }
    }
  }
  &-form {
    &-content {
      form {
        .form-group {
          margin-bottom: 26px;
          @include mdq(xs-md) {
            margin-bottom: 6px;
          }
          .file-input-notes {
            font-size: 14px;
            line-height: 150%;
            @include sohne-leicht;
            color: $color-dark-grey;
            @include mdq(xs-md) {
              font-size: 12px;
            }
          }
          label {
            font-size: 24px;
            line-height: 150%;
            @include sohne-leicht;
            color: $color-dark-grey;
            padding-bottom: 20px;
            display: block;
            @include mdq(xs-md) {
              padding-bottom: 15px;
              font-size: 16px;
            }
          }
          .file {
            display: flex;
            align-items: center;
            margin-bottom: 60px;
            @include mdq(xs-md) {
              margin-bottom: 30px;
            }
            #fileLoader {
              display: none;
            }
            input {
              font-size: 24px !important;
              line-height: 150%;
              @include sohne-leicht;
              padding: 10px 20px !important;
              color: $color-dark-grey !important;
              border: 1px solid $color-light-grey !important;
              background-color: transparent;
              cursor: pointer;
              width: fit-content;
              @include mdq(xs-md) {
                font-size: 16px !important;
              }
            }
            span {
              font-size: 24px;
              line-height: 150%;
              @include sohne-leicht;
              color: $color-dark-grey;
              margin-left: 30px;
              @include mdq(xs-md) {
                font-size: 16px;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}

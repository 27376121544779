*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  @include sohne-leicht;
}

*:focus {
  outline: none;
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
h1 {
  font-size: 32px;
  line-height: 1.5;
  @include mdq(xs-md) {
    font-size: 20px;
  }
}
h2 {
  font-size: 24px;
  line-height: 1.5;
  @include mdq(xs-md) {
    font-size: 18px;
  }
}
h3 {
  font-size: 16px;
  line-height: 1.5;
  @include mdq(xs-md) {
    font-size: 16px;
  }
}
h4 {
  font-size: 14px;
  line-height: 1.5;
}
.mb-control {
  @include mdq(xs-md) {
    margin-bottom: 40px;
  }
}
a:hover,
a {
  text-decoration: none !important;
}

main {
  overflow-x: hidden;
}

.sm-md-overflow-none {
  @include mdq(xs-md) {
    overflow: hidden;
  }
}

.container {
  @media only screen and (min-width: 1200px) {
    max-width: 1200px !important;
  }
  @media only screen and (min-width: 1680px) {
    max-width: 1400px !important;
  }
  @media only screen and (min-width: 1800px) {
    max-width: 1600px !important;
  }
}
.container-fluid {
  padding: 0 35px !important;
  @include mdq(xs-md) {
    padding: 0 5px !important;
  }
  .row {
    margin: 0 !important;
  }
}

.header-about {
  .is-about {
    display: block !important;
    @include mdq(xs-md) {
      display: none !important;
    }
  }
}

.main-about {
  padding: 360px 0 40px;

  @include mdq(xs-md) {
    padding: 120px 0 20px;
  }

  .about-description {
    margin-bottom: 117px;
    @include mdq(xs-md) {
      margin-bottom: 40px;
    }

    p {
      @include sohne-leicht;
      color: $color-dark-grey;
      font-size: 24px;
      line-height: 150%;
      @include mdq(xs-md) {
        font-size: 20px;
      }
    }
  }

  .slider-content {
    margin-bottom: 0;

    .owl-dots {
      position: unset;
      display: flex;
      width: 100% !important;
      justify-content: center;
      padding: 0;
      margin-top: 25px !important;
      background-color: transparent;
      @include mdq(xs-md) {
        margin-top: 15px !important;
      }
    }
  }

  .about-team {
    margin-top: 130px;
    @include mdq(xs-md) {
      margin-top: 60px;
    }

    &-image-text {
      .row {
        @include mdq(xs-md) {
          flex-direction: column-reverse;
        }
      }
    }

    &-person {
      display: block;
      padding-top: 160px;
      @include mdq(xs-md) {
        padding-top: 60px;
      }
    }

    &-worker {
      margin-top: 160px;
      @include mdq(xs-md) {
        margin-top: 20px;
      }

      .col-lg-3 {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          .about-team-item {
            border-top: 1px solid $color-light-grey;
          }
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          .about-team-item {
            @include mdq(xs-md) {
              border-top: none;
            }
          }
        }
      }

      .about-team-head {
        margin-bottom: 60px;
        @include mdq(xs-md) {
          margin-bottom: 30px;
        }
      }
    }

    &-image {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &-text {
      p {
        @include sohne-leicht;
        color: $color-dark-grey;
        font-size: 24px;
        line-height: 150%;
        @include mdq(xs-md) {
          margin-bottom: 40px;
          font-size: 20px;
        }
      }
    }

    &-head {
      @include mdq(xs-md) {
        margin-bottom: 30px;
      }

      h3 {
        @include sohne-leicht;
        color: $color-dark-grey;
        font-size: 32px;
        line-height: 36px;
        @include mdq(xs-md) {
          font-size: 24px;
        }
      }
    }

    &-item {
      padding: 15px 0;
      border-bottom: 1px solid $color-light-grey;

      &:hover {
        h6,
        span {
          transform: translateX(7px);
        }
      }

      h6 {
        @include sohne-leicht;
        color: $color-dark;
        font-size: 16px;
        line-height: 150%;
        transition: all 0.3s ease-in;
      }

      span {
        @include sohne-leicht;
        display: block;
        color: $color-dark-grey;
        font-size: 16px;
        line-height: 150%;
        transition: all 0.3s ease-in;
      }
    }

    &-founder {
      @include mdq(xs-md) {
        margin-bottom: 40px;
      }

      &-image {
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      &-text {
        margin-top: 30px;

        h5 {
          @include sohne-leicht;
          margin-bottom: 30px;
          color: $color-dark;
          font-size: 24px;
          line-height: 27px;
          @include mdq(xs-md) {
            margin-bottom: 20px;
            font-size: 20px;
          }
        }

        p {
          @include sohne-leicht;
          color: $color-dark-grey;
          font-size: 16px;
          line-height: 170%;
          @include mdq(xs-md) {
            line-height: 150%;
          }
        }
      }
    }
  }

  .about-content-list {
    margin-top: 160px;
    @include mdq(xs-md) {
      margin-top: 60px;
    }

    .general-list {
      margin-bottom: 120px;
      @include mdq(xs-md) {
        margin-bottom: 60px;
      }
    }
  }
}

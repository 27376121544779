.general-list {
  position: relative;
  width: 100%;
  &-head {
    padding-left: 15px;
    margin-bottom: 60px;
    @include mdq(xs-md) {
      margin-bottom: 30px;
    }
    h3 {
      color: $color-dark-grey;
      @include sohne-leicht;
      font-size: 32px;
      line-height: 36px;
      @include mdq(xs-md) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .general-list-item {
    display: flex;
    width: 100%;
    align-items: center;

    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: $color-light-grey;
      width: calc(100% - 30px);
      bottom: 0;
      left: 15px;
    }
    &:hover {
      z-index: 99;
      .general-list-item-image {
        opacity: 1;
        visibility: visible;
      }
    }
    .row {
      width: 100%;
    }
    &-image {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 85px;
      width: auto;
      height: auto;
      opacity: 0;
      transform: translateY(-50%);
      transition: 400ms opacity, 400ms visibility;
      visibility: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    &:nth-child(3) {
      @include mdq(xs-md) {
        border-top: 1px solid $color-light-grey;
      }
    }
    &:nth-child(2) {
      @include mdq(xs-md) {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
      .general-list-item-content p {
        color: rgba($color: $color-dark, $alpha: 0.5);
      }
      &:hover {
        .general-list-item-content p {
          text-decoration: none;
        }
      }
    }
    a {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px 0;
      text-decoration: none;
      @include mdq(xs-md) {
        padding: 10px 0;
      }

      &:hover {
        .general-list-item-content {
          p,
          span {
            text-decoration: underline;
          }
          span {
            color: $color-dark;
          }
        }
      }
    }

    &-content {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      @include mdq(xs-md) {
        margin-bottom: 5px;
      }
      span {
        @include sohne-leicht;
        color: $color-dark-grey;
        font-size: 16px;
        line-height: 150%;
      }

      p {
        @include sohne-leicht;
        color: $color-dark;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}

.body-worker-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  header,
  footer {
    display: none;
  }

  .cookie {
    display: none;
  }
}
// .main-worker-modal {
//   height: 60% !important;
//   width: 50% !important;
//   background-color: #fff;
//   @media only screen and (min-width: 250px) and (max-width: 768px) {
//     height: 55% !important;
//     width: calc(100% - 40px) !important;
//   }
// }
.main-worker-modal {
  width: 100%;
}

.worker-modal {
  padding: 40px;
  background-color: #fff;
  @media only screen and (min-width: 250px) and (max-width: 768px) {
    padding: 20px;
  }

  .container-fluid {
    position: relative;
    z-index: 7;
    padding: 0 !important;
  }

  &-bg {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-action {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    a {
      @include sohne-leicht;
      display: flex;
      align-items: center;
      color: $color-dark-grey;
      font-size: 16px;
      line-height: 150%;

      i {
        display: flex;
        width: 12px;
        height: 12px;
        margin-left: 10px;

        svg {
          width: 100%;
          height: 100%;
        }
        @media only screen and (min-width: 250px) and (max-width: 730px) {
          width: 10px;
          height: 10px;
          margin-left: 6px;
        }
      }
      @media only screen and (min-width: 250px) and (max-width: 730px) {
        font-size: 14px;
      }
    }
  }

  &-head {
    margin-top: 6px;

    img {
      width: 95px;
      height: 95px;
      object-fit: cover;
      @media only screen and (min-width: 250px) and (max-width: 768px) {
        width: 65px;
        height: 65px;
      }
    }

    h4 {
      @include sohne-leicht;
      margin-top: 30px;
      color: $color-dark;
      font-size: 24px;
      line-height: 44px;
      @media only screen and (min-width: 250px) and (max-width: 768px) {
        margin-top: 20px;
        font-size: 18px;
        line-height: 27px;
      }
    }

    span {
      @include sohne-leicht;
      color: $color-dark-grey;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-body {
    padding-bottom: 20px;
    margin-top: 30px;
    @media only screen and (min-width: 250px) and (max-width: 600px) {
      margin-top: 10px;
    }

    p {
      @include sohne-leicht;
      color: $color-dark-grey;
      font-size: 16px;
      line-height: 24px;
    }

    a {
      @include sohne-leicht;
      display: block;
      width: fit-content;
      margin-top: 30px;
      color: $color-dark-grey;
      font-size: 16px;
      line-height: 24px;
      text-decoration: underline !important;
      transition: 0.4s ease-in all;

      i {
        display: flex;

        svg {
          width: 25px;
          height: 25px;
          @media only screen and (min-width: 250px) and (max-width: 768px) {
            width: 24px;
            height: 24px;
          }
        }
      }

      &:first-child {
        @media only screen and (min-width: 250px) and (max-width: 768px) {
          margin-bottom: 30px;
        }
      }

      &:hover {
        color: $color-dark;

        i svg {
          circle {
            fill: #fff;
          }

          path {
            fill: $color-dark;
          }
        }
      }

      i svg circle,
      i svg path {
        transition: all 0.4s ease-in;
      }
      @media only screen and (min-width: 250px) and (max-width: 768px) {
        margin-top: 15px;
      }
    }
  }
}

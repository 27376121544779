.main-privacy-policy {
  overflow: unset;
}
.privacy-policy {
  padding: 200px 0 160px 0;
  position: relative;
  @include mdq(xs-md) {
    padding: 120px 0 80px 0;
  }
  &-title {
    position: sticky;
    top: 100px;
    @include mdq(xs-md) {
      margin-bottom: 30px;
    }
    h2 {
      font-size: 32px;
      line-height: 36px;
      color: $color-dark;
      @include sohne-leicht;
      @include mdq(xs-md) {
        font-size: 24px;
        line-height: 1;
      }
    }
  }
  &-text {
    article {
      p,
      h4,
      li {
        font-size: 24px;
        line-height: 150%;
        color: $color-dark-grey;
        @include sohne-leicht;
        @include mdq(xs-md) {
          font-size: 20px;
        }
      }
      p,
      h4 {
        margin-bottom: 50px;
        @include mdq(xs-md) {
          margin-bottom: 45px;
        }
      }
      ul {
        li {
          list-style: inside;
          margin-bottom: 45px;
          padding-left: 25px;
          @include mdq(xs-md) {
            margin-bottom: 40px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}

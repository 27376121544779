.fancybox-bg {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-content {
  z-index: 3 !important;
  max-width: 800px !important;
  height: 50vh !important;
  background: transparent !important;
  @include mdq(xs-md) {
    width: calc(100% - 40px) !important;
    max-width: unset;
    height: 60vh !important;
  }
  @media screen and (max-height: 545px) {
    height: 95vh !important;
  }
  @media screen and (min-height: 546px) and (max-height: 800px) {
    height: 85vh !important;
  }
}

.fancybox-bg {
  background: rgba(0, 0, 0, 0.5) !important;
}

.fancybox-stage {
  margin: 0 auto !important;
  @media only screen and (min-width: 250px) and (max-width: 768px) {
    width: calc(100% - 40px) !important;
  }
}

.body-error-page {
  footer {
    display: none;
  }
  .is-open {
    .header-contain-item.is-mobile {
      top: 82px;
      height: calc(100vh - 82px);
    }
  }
}
.main-error-page {
  padding-top: 110px;
  .error-page-content {
    position: relative;
    height: calc(100vh - 110px);
    display: grid;
    place-items: center;

    &-item {
      position: relative;
      z-index: 10;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include mdq(xs-md) {
        padding-top: 0;
      }

      &-title {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;

        h1 {
          color: $color-dark;
          @include sohne-buch;
          font-size: 180px;
          line-height: 1;
          margin: 0;
          text-align: center;
          @include mdq(xs-md) {
            font-size: 100px;
          }
          @media only screen and (min-width: 2200px) {
            font-size: 220px;
          }
        }
      }

      &-page-not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        h4 {
          @include sohne-buch;
          color: $color-dark;
          font-size: 32px;
          line-height: 1.5;
          text-align: center;
          margin-bottom: 20px;
          @include mdq(xs-md) {
            font-size: 20px;
            margin-bottom: 15px;
          }
        }
        .btn-primary {
          line-height: 1.2;
        }
      }
    }

    .image-first {
      position: absolute;
      top: 9%;
      left: 6%;
      width: auto;
      height: auto;
      animation-delay: 0s, 0.3s;
      animation-direction: normal, alternate;
      animation-duration: 0.3s, 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1, infinite;
      animation-name: hvr-bob-float, hvr-bob;
      animation-timing-function: ease-out, ease-in-out;
      transform: translateY(-12px);

      @keyframes hvr-bob {
        0% {
          transform: translateY(-12px);
        }

        50% {
          transform: translateY(-8px);
        }

        100% {
          transform: translateY(-12px);
        }
      }

      @include mdq(xs-md) {
        top: 17%;
        left: 0;
      }
      @include mdq(md) {
        width: 40vw;
        height: 22vh;
      }
      @include mdq(xs) {
        width: 42vw;
        height: 16vh;
      }
      @media only screen and (min-width: 2200px) {
        width: 15%;
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image-second {
      position: absolute;
      top: 5%;
      right: 13%;
      width: auto;
      height: auto;
      animation-delay: 0s, 0.4s;
      animation-direction: normal, alternate;
      animation-duration: 0.4s, 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1, infinite;
      animation-name: hvr-bob-float, hvr-bob;
      animation-timing-function: ease-out, ease-in-out;
      transform: translateY(-12px);

      @keyframes hvr-bob {
        0% {
          transform: translateY(-12px);
        }

        50% {
          transform: translateY(-8px);
        }

        100% {
          transform: translateY(-12px);
        }
      }

      @include mdq(xs-md) {
        top: 22%;
        right: 4%;
      }
      @include mdq(md) {
        width: 32vw;
        height: 18vh;
      }
      @include mdq(xs) {
        width: 35vw;
        height: 14vh;
      }
      @media only screen and (min-width: 2200px) {
        width: 12%;
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image-third {
      position: absolute;
      bottom: 9%;
      left: 15%;
      width: auto;
      height: auto;
      animation-delay: 0s, 0.5s;
      animation-direction: normal, alternate;
      animation-duration: 0.5s, 2.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1, infinite;
      animation-name: hvr-bob-float, hvr-bob;
      animation-timing-function: ease-out, ease-in-out;
      transform: translateY(-12px);
      @media screen and ( max-height: 800px ){
        bottom: 5%;
    }
      @keyframes hvr-bob {
        0% {
          transform: translateY(-12px);
        }

        50% {
          transform: translateY(-8px);
        }

        100% {
          transform: translateY(-12px);
        }
      }

      @include mdq(xs-md) {
        bottom: 16%;
        left: 0;
      }
      @include mdq(xs) {
        width: 37vw;
        height: 14vh;
      }
      @include mdq(md) {
        width: 32vw;
        height: 20vh;
      }
      @media only screen and (min-width: 2200px) {
        width: 14%;
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image-fourth {
      position: absolute;
      bottom: 14%;
      right: 0;
      width: auto;
      height: auto;
      animation-delay: 0s, 0.3s;
      animation-direction: normal, alternate;
      animation-duration: 0.3s, 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1, infinite;
      animation-name: hvr-bob-float, hvr-bob;
      animation-timing-function: ease-out, ease-in-out;
      transform: translateY(-12px);
      @media screen and ( max-height: 800px ){
          bottom: 7%;
      }

      @keyframes hvr-bob {
        0% {
          transform: translateY(-12px);
        }

        50% {
          transform: translateY(-8px);
        }

        100% {
          transform: translateY(-12px);
        }
      }

      @include mdq(xs-md) {
        bottom: 5%;
        right: 0;
      }
      @include mdq(xs) {
        width: 44vw;
        height: 17vh;
      }
      @include mdq(md) {
        width: 35vw;
        height: 25vh;
      }
      @media only screen and (min-width: 2200px) {
        width: 20%;
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        position: absolute;
        z-index: -1;
        top: -1.2vh;
        right: 0;
        width: 94%;
        height: 18px;
        background: #b54333;
        content: '';
      }
    }
  }
}

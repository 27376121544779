.main-home {
  padding-top: 200px;
  @include mdq(xs-md) {
    padding-top: 120px;
  }
}

.home-text {
  padding: 0 0 130px;
  @include mdq(xs-md) {
    padding: 0 0 50px;
    margin-bottom: 60px;
  }

  p {
    @include sohne-leicht;
    margin-bottom: 30px;
    color: $color-dark-grey;
    font-size: 24px;
    line-height: 150%;
    @include mdq(xs-md) {
      font-size: 20px;
    }
  }
}

.component-content {
  margin-bottom: 220px;
  @include mdq(xs-md) {
    margin-bottom: 60px;
  }

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    @include mdq(xs-md) {
      margin-bottom: 30px;
    }

    h3 {
      @include sohne-leicht;
      color: $color-dark-grey;
      font-size: 32px;
      line-height: 36px;
      @include mdq(xs-md) {
        font-size: 24px;
        line-height: 27px;
      }
    }
  }

  &-body {
    width: 100%;

    .mb-control {
      @include mdq(xs-md) {
        margin-bottom: 40px;
      }
    }
  }
}

.slider-content {
  margin-bottom: 150px;
  @include mdq(xs-md) {
    margin-bottom: 60px;
  }

  .owl-dots {
    min-height: 72px;
    padding-top: 0 !important;
    @include mdq(xs-md) {
      position: relative;
      min-height: 0 !important;
      padding-top: 20px !important;
      padding-left: 0 !important;
      margin: 0 auto;
    }
  }

  .owl-theme .item {
    position: relative;

    img {
      width: 100%;
      // height: 840px;
      min-height: 800px;
      object-fit: cover;
      @include mdq(xs) {
        height: 200px;
        min-height: auto;
      }
      @include mdq(md) {
        height: 500px;
        min-height: auto;
      }
      @include mdq(lg) {
        // height: 650px;
        min-height: auto;
      }
      @media only screen and (min-width: 1680px) {
        // height: 840px;
      }
      @media only screen and (min-width: 2000px) {
        // height: 1000px;
      }
    }

    .slider-item-text {
      min-height: 72px;
      margin-top: 30px;
      background-color: #fff;
      @include mdq(xs-md) {
        min-height: auto;

        &:empty {
          margin-top: 0;
        }
      }

      p,
      h2,
      h3 {
        @include sohne-buch;
        color: $color-dark-grey;
        font-size: 16px;
        line-height: 24px;
        @include mdq(xs-md) {
          font-size: 14px;
          line-height: 21px;
        }

        a,
        strong {
          @include sohne-kraftig-kursiv;
          color: $color-dark-grey;

          em {
            @include sohne-kraftig-kursiv;
            color: $color-dark-grey;
          }
        }
      }
    }
  }
}

.btn {
  flex-shrink: 0;
  border: none;
  background-color: #fff;
  cursor: pointer;
  color: $color-dark-grey;
  padding: 0;
  transition: all 0.3s ease;

  &:hover {
    color: $color-dark;
    border-bottom: 1px solid $color-dark;
    i {
      transform: translateX(5px);
      svg path {
        transition: all 0.4s ease;
        fill: $color-dark !important;
      }
    }
  }
  &:focus {
    color: $color-dark;
    border-bottom: 1px solid $color-dark;
    i {
      svg path {
        transition: all 0.4s ease;
        fill: $color-dark !important;
      }
    }
  }
  &.btn-primary {
    line-height: 150%;
    font-size: 24px;
    @include sohne-leicht;
    @include mdq(xs-md) {
      font-size: 16px;
    }
    &.is-icon {
      border-spacing: 1px;
      border-bottom: 1px solid $color-dark-grey;
      display: flex;
      align-items: center;
      width: auto;
      i {
        transition: all 0.3s ease;
        margin-left: 10px;
        display: flex;
        align-items: flex-end;
        svg path {
          fill: $color-dark-grey;
        }
      }
    }
  }
  &.btn-secondary {
    border-bottom: 1px solid $color-dark-grey;
    font-size: 16px;
    line-height: 18px;
    @include sohne-buch;
    @include mdq(xs-md) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  &-image {
    overflow: hidden;
    margin-bottom: 30px;
    @include mdq(xs-md) {
      margin-bottom: 15px;
    }

    img {
      transition: 0.8s ease all;
      width: 100%;
      height: auto;
      object-fit: cover;
      &:hover {
        transform: scale(1.03);
      }
    }
  }
  &-text {
    h4 {
      color: $color-dark;
    }
  }
  &.is-project {
    h4 {
      font-size: 24px;
      line-height: 27px;
      @include sohne-leicht;
      @include mdq(xs-md) {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  &.is-news {
    h4 {
      font-size: 16px;
      line-height: 1.5;
      @include sohne-buch;
    }
  }
}

.main-news-detail {
  overflow: unset;
  padding: 200px 0 160px;
  @include mdq(xs-md) {
    padding: 120px 0 80px;
  }

  .news-detail {
    &-description {
      position: sticky;
      top: 100px;
      display: flex;
      width: 100%;
      flex-direction: column;
      @include mdq(xs-md) {
        margin-bottom: 40px;
      }

      &-date {
        margin-bottom: 40px;
        @include mdq(xs-md) {
          margin-bottom: 30px;
        }

        span {
          @include sohne-leicht;
          color: $color-dark;
          font-size: 24px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 20px;
          }
        }
      }

      &-head {
        margin-bottom: 40px;
        @include mdq(xs-md) {
          margin-bottom: 30px;
        }

        h2 {
          @include sohne-leicht;
          color: $color-dark;
          font-size: 32px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 24px;
          }
          @media only screen and (min-width: 1440px) {
            width: 90%;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            width: 70%;
          }
          @media only screen and (min-width: 1900px) {
            width: 80%;
          }
        }
      }

      &-person {
        margin-bottom: 60px;
        @include mdq(xs-md) {
          margin-bottom: 30px;
        }

        p {
          @include sohne-leicht;
          color: $color-dark-grey;
          font-size: 24px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 20px;
          }

          span {
            color: $color-dark;
          }
        }
      }

      &-social {
        &-head {
          margin-bottom: 20px;
          @include mdq(xs-md) {
            margin-bottom: 15px;
          }

          p {
            @include sohne-leicht;
            color: $color-dark-grey;
            font-size: 24px;
            line-height: 150%;
            @include mdq(xs-md) {
              font-size: 20px;
            }
          }
        }

        &-body {
          display: flex;

          a {
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 15px;
            }

            &:hover {
              i svg {
                circle {
                  fill: #fff;
                }

                path {
                  fill: $color-dark;
                }
              }
            }

            svg path,
            svg circle {
              transition: all 0.4s ease-in;
            }

            i {
              display: flex;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    &-article {
      article {
        img {
          width: 100%;
          height: auto;
          margin-bottom: 80px;
          object-fit: cover;
          @include mdq(xs-md) {
            margin-bottom: 40px;
          }
        }

        .article-item {
          margin-bottom: 80px;
          @include mdq(xs-md) {
            margin-bottom: 40px;
          }
        }

        p {
          @include sohne-leicht;
          margin-bottom: 24px;
          color: $color-dark-grey;
          font-size: 24px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 20px;
          }

          strong,
          b {
            @include sohne-kraftig;
          }

          i {
            font-style: italic;
          }

          a {
            color: $color-dark-grey;
            text-decoration: none;

            strong {
              @include sohne-kraftig;
              color: $color-dark-grey;
            }
          }
        }

        em,
        mark,
        del,
        ins,
        sub sup {
          @include sohne-leicht;
          margin-bottom: 24px;
          color: $color-dark-grey;
          font-size: 24px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 20px;
          }
        }

        small {
          @include sohne-leicht;
          font-size: 18px;
          line-height: 1.4%;
        }

        i {
          font-style: italic;
        }

        ol {
          list-style-position: inside;

          li {
            a {
              color: $color-dark;
              text-decoration: none;
            }
          }
        }

        ul {
          list-style-type: disc;

          li {
            a {
              color: $color-dark;
              text-decoration: none;
            }
          }
        }

        a {
          @include sohne-leicht;
          margin-bottom: 24px;
          color: $color-dark-grey;
          font-size: 24px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 20px;
          }

          strong {
            color: $color-dark-grey;
          }

        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @include sohne-kraftig;
          margin-bottom: 24px;
          color: $color-dark-grey;
          line-height: 150%;

          a,
          strong {
            @include sohne-kraftig;
            color: $color-dark-grey;
          }

        }

        h1 {
          font-size: 42px;
        }

        h2 {
          font-size: 36px;
        }

        h3 {
          font-size: 32px;
        }

        h4 {
          font-size: 28px;
        }

        h5 {
          font-size: 24px;
        }

        h6 {
          font-size: 20px;
        }

        ul,
        ol {
          @include sohne-leicht;
          padding-left: 25px;
          margin-bottom: 24px;
          color: $color-dark-grey;
          font-size: 24px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 20px;
          }

          strong {
            @include sohne-kraftig;
          }

          i {
            font-style: italic;
          }
        }

        .video {
          margin-bottom: 80px;
          @include mdq(xs-md) {
            margin-bottom: 40px;
          }
        }

        .row {
          .col-6 {
            &:first-child {
              padding-left: 0 !important;
            }

            &:last-child {
              padding-right: 0 !important;
            }
          }
        }

        .description-image {
          margin-bottom: 80px;
          @include mdq(xs-md) {
            margin-bottom: 30px;
          }

          img {
            margin-bottom: 15px;
            @include mdq(xs-md) {
              margin-bottom: 10px;
            }
          }

          h5 {
            @include sohne-leicht;
            color: $color-dark-grey;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            @include mdq(xs-md) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .component-content {
    margin-top: 160px;
    margin-bottom: 0;
    @include mdq(xs-md) {
      margin-top: 60px;
    }

    &-body {
      .col-lg-3 {
        &:nth-child(4),
        &:nth-child(3) {
          @include mdq(xs-md) {
            display: none;
          }
        }
      }
    }
  }
}

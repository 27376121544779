.main-project-detail {
  padding: 160px 0;
  @include mdq(xs-md) {
    padding: 120px 0 80px;
  }

  .slider-content {
    margin-bottom: 0;
    // height: calc(100vh - 50px);
    .container-fluid,
    .owl-carousel,
    .owl-stage-outer,
    .owl-stage,
    .owl-item,
    .item {
      // height: 100%;
    }

    img {
      // height: 100% !important;
    }

    .icon-arrow-down {
      position: absolute;
      z-index: 9999;
      bottom: 120px;
      display: flex;
      width: 100%;
      justify-content: center;
      animation-direction: normal, alternate;
      animation-duration: 2.3s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: mouse-scroll;
      animation-timing-function: ease-out, ease-in-out;
      cursor: pointer;
      @include mdq(xs-md) {
        bottom: 10px;
      }
    }

    @keyframes mouse-scroll {
      0% {
        transform: translateY(-7px);
        transform: translateY(-7px);
      }

      50% {
        transform: translateY(-3px);
        transform: translateY(-3px);
      }

      100% {
        transform: translateY(-7px);
        transform: translateY(-7px);
      }
    }

    .owl-dots {
      position: unset;
      display: flex;
      width: 100% !important;
      justify-content: center;
      padding: 0;
      margin-top: 25px !important;
      background-color: transparent;
      @include mdq(xs-md) {
        margin-top: 15px !important;
      }
    }
  }

  .project-detail {
    padding: 110px 0 100px;
    @include mdq(xs-md) {
      padding: 40px 0;
    }

    &-content {
      &-video {
        padding: 0 15px;
      }

      &-head {
        @include mdq(xs-md) {
          margin-bottom: 30px;
        }

        h2 {
          @include sohne-leicht;
          color: $color-dark;
          font-size: 32px;
          line-height: 36px;
          @include mdq(xs-md) {
            font-size: 20px;
            line-height: 150%;
          }
        }
      }

      &-description {
        padding: 110px 0 100px;
        @include mdq(xs-md) {
          padding: 40px 0;
        }

        .col-lg-8 {
          .row {
            .col-lg-6 {
              padding-left: 0 !important;
            }

            .col-lg-5 {
              padding-left: 45px !important;
              @include mdq(xs-md) {
                padding-left: 0 !important;
              }
            }
          }
        }

        &-date {
          margin-bottom: 37px;
          @include mdq(xs-md) {
            margin-bottom: 30px;
          }

          p {
            @include sohne-leicht;
            color: $color-dark-grey;
            font-size: 24px;
            line-height: 150%;
            @include mdq(xs-md) {
              font-size: 18px;
              line-height: 1;
            }
          }
        }

        &-text {
          @include mdq(xs-md) {
            margin-bottom: 30px;
          }

          p {
            @include sohne-leicht;
            color: $color-dark-grey;
            font-size: 24px;
            line-height: 150%;
            @include mdq(xs-md) {
              font-size: 20px;
            }

            strong {
              @include sohne-kraftig;
            }

            i {
              font-style: italic;
            }

            &:not(:last-child) {
              margin-bottom: 60px;
              @include mdq(xs-md) {
                margin-bottom: 40px;
              }
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            @include sohne-kraftig;
            margin-bottom: 24px;
            color: $color-dark-grey;
            line-height: 150%;
          }

          h1 {
            font-size: 42px;
          }

          h2 {
            font-size: 36px;
          }

          h3 {
            font-size: 32px;
          }

          h4 {
            font-size: 28px;
          }

          h5 {
            font-size: 24px;
          }

          h6 {
            font-size: 20px;
          }

          ul,
          ol {
            @include sohne-leicht;
            padding-left: 25px;
            margin-bottom: 24px;
            color: $color-dark-grey;
            font-size: 24px;
            line-height: 150%;
            @include mdq(xs-md) {
              font-size: 20px;
            }

            strong {
              @include sohne-kraftig;
            }

            i {
              font-style: italic;
            }
          }
        }

        &-information {
          display: flex;
          flex-direction: column;

          &-item {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            p {
              @include sohne-leicht;
              color: $color-dark-grey;
              font-size: 16px;
              line-height: 150%;

              strong {
                @include sohne-kraftig;
              }

              i {
                font-style: italic;
              }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              @include sohne-kraftig;
              margin-bottom: 24px;
              color: $color-dark-grey;
              line-height: 150%;
            }

            h1 {
              font-size: 42px;
            }

            h2 {
              font-size: 36px;
            }

            h3 {
              font-size: 32px;
            }

            h4 {
              font-size: 28px;
            }

            h5 {
              font-size: 24px;
            }

            h6 {
              font-size: 20px;
            }

            ul {
              @include sohne-leicht;
              padding-left: 25px;
              margin-bottom: 24px;
              color: $color-dark-grey;
              font-size: 24px;
              line-height: 150%;
              @include mdq(xs-md) {
                font-size: 20px;
              }

              strong {
                @include sohne-kraftig;
              }

              i {
                font-style: italic;
              }
            }

            span {
              @include sohne-leicht;
              color: $color-dark;
              font-size: 16px;
              line-height: 150%;
            }
          }
        }
      }

      &-images {
        &-item {
          margin-bottom: 80px;
          @include mdq(xs-md) {
            margin-bottom: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            @include mdq(xs-md) {
              margin-bottom: 20px;
            }
          }
        }
      }

      &-text {
        padding: 120px 0;
        @include mdq(xs-md) {
          padding: 20px 0 40px;
        }

        p {
          @include sohne-leicht;
          color: $color-dark-grey;
          font-size: 24px;
          line-height: 150%;
          @include mdq(xs-md) {
            font-size: 20px;
          }

          &:not(:last-child) {
            margin-bottom: 50px;
            @include mdq(xs-md) {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }

  .component-content {
    margin-top: 160px;
    margin-bottom: 0;
    @include mdq(xs-md) {
      margin-top: 60px;
    }
  }
}

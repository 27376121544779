.footer {
  padding: 60px 0;
  border-top: 1px solid $color-light-grey;
  @include mdq(xs-md) {
    padding: 40px 0 30px;
  }

  &-menu {
    nav {
      ul {
        display: flex;
        flex-direction: column;

        li {
          list-style: none;

          a {
            @include sohne-buch;
            color: $color-dark-grey;
            font-size: 16px;
            line-height: 38px;
            transition: all 0.5s ease-in;
            @include mdq(xs-md) {
              font-size: 18px;
              line-height: 40px;
            }

            &:hover {
              color: $color-dark;
            }
          }
        }
      }
    }
  }

  &-item {
    @include mdq(xs-md) {
      margin-bottom: 40px;
    }

    &.mobile-control {
      @include mdq(xs-md) {
        margin-top: 40px;
      }
    }

    &-dropdown {
      @include mdq(lg) {
        width: calc(100% + 25px);
      }

      ul {
        position: relative;
        padding-top: 30px;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 12px;
          height: 12px;
          margin-bottom: 30px;
          background-color: $color-dark-grey;
          border-radius: 50%;
          content: '';
          transition: all 0.5s ease-in;
        }

        li {
          padding-bottom: 50px;
          list-style: none;
          @include mdq(xs-md) {
            padding-bottom: 40px;
          }

          &.is-active {
            &:hover {
              ul {
                display: block !important;
              }

              a {
                span {
                  color: #000;
                }

                i {
                  svg {
                    transform: rotate(0deg);

                    path {
                      fill: #000;
                    }
                  }
                }
              }
            }
          }

          a {
            @include sohne-buch;
            padding-top: 0;
            padding-bottom: 10px;
            color: #878a8c;
            font-size: 16px;
            line-height: 150%;

            span {
              @include sohne-buch;
              color: #878a8c;
              font-size: 16px;
              line-height: 150%;
            }

            &::before {
              display: none;
            }

            i {
              margin-left: 3px;

              svg {
                transform: rotate(180deg);
                transition: all 0.4s ease-in;
              }
            }
          }

          ul {
            position: absolute;
            top: 70px;
            display: none;
            padding-top: 0;
            padding-bottom: 0;

            &::before {
              content: none;
            }

            li {
              a {
                @include sohne-buch;
                padding-top: 0;
                color: #000;
                font-size: 16px;
                line-height: 150%;

                &:hover {
                  color: #000;
                }

                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    a {
      @include sohne-buch;
      position: relative;
      display: block;
      padding-top: 30px;
      color: $color-dark-grey;
      font-size: 16px;
      line-height: 1.5;
      transition: all 0.5s ease-in;

      &:hover {
        color: $color-dark;

        &::before {
          background-color: $color-dark;
        }
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 12px;
        height: 12px;
        margin-bottom: 30px;
        background-color: $color-dark-grey;
        border-radius: 50%;
        content: '';
        transition: all 0.5s ease-in;
      }
    }
  }

  &-information {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 95px;
    @include mdq(xs-md) {
      flex-direction: column;
      margin-top: 0;
    }

    &-item {
      &:not(:last-child) {
        @include mdq(xs-md) {
          margin-bottom: 15px;
        }
      }

      p {
        @include sohne-buch;
        color: rgba($color: $color-dark-grey, $alpha: 0.5);
        font-size: 14px;
        line-height: 1.5;

        a {
          @include sohne-buch;
          color: rgba($color: $color-dark-grey, $alpha: 0.5);
          font-size: 14px;
          line-height: 1.5;
          text-decoration: none;

          &:hover {
            color: rgba($color: $color-dark-grey, $alpha: 1);
          }
        }
      }
    }
  }

  .col-lg-1 {
    .footer-item {
      @include mdq(xs-md) {
        margin-bottom: 0;
      }
    }
  }
}

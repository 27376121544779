// stylelint-disable

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.fadeInUp-scroll {
  opacity: 0;
  transform: translateY(50px);

  &[data-position*='left-to-right'] {
    transform: translateX(-50px);
  }

  &[data-position*='right-to-left'] {
    transform: translateX(50px);
  }

  &[data-position*='top-to-bottom'] {
    transform: translateY(-50px);
  }

  &[data-position*='bottom-to-top'] {
    transform: translateY(50px);
  }

  &.visible {
    opacity: 1 !important;
    transform: translateY(0) !important;
    transition: transform 0.8s, opacity 0.8s;

    &[data-position*='left-to-right'],
    &[data-position*='right-to-left'] {
      transform: translateX(0) !important;
    }
  }
}

.scale-scroll {
  opacity: 0;
  transform: scale(0.9);

  &.visible {
    opacity: 1 !important;
    transform: scale(1) !important;
    transition: all 1s;
  }
}

.image-animation {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    transition: left 600ms cubic-bezier(0.835, 0.015, 0.245, 0.995) 0s;
  }

  &::after {
    content: '';
    height: 100%;
    width: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(#f7f7f9, 0.95);
  }

  &.animate {
    &::before {
      left: 100%;
    }
  }
}

@keyframes image-animation {
  from {
    background-color: $white;
  }

  to {
    background-color: transparent;
  }
}

@keyframes image-animation-filter {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }
}

.text-title-animation {
  span {
    opacity: 0;
    visibility: hidden;
    transition: 300ms opacity, 300ms visibility;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.cookie {
  position: fixed;
  z-index: 20;
  bottom: 0;
  right: 50px;
  background: #000;
  box-shadow: 0px 0px 3px rgba(99, 101, 107, 0.11);
  transform: translateY(100%);
  transition: 0.5s transform;
  @include mdq(xs-md) {
    right: 20px;
    left: 20px;
  }
  .cookie-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    cursor: pointer;
  }
  &.show {
    transform: translateY(-40px);
    @include mdq(xs-md) {
      transform: translateY(-15px);
    }
  }
  .cookie-contain {
    flex-direction: row;
    display: flex;
    padding: 30px 40px 30px 30px;
    @include mdq(xs-md) {
      flex-direction: column;
    }
    @include mdq(xs) {
      padding: 15px 15px 20px 15px;
    }
    &-description {
      p {
        @include sohne-leicht;
        max-width: 250px;
        margin-bottom: 0px;
        color: #fff;
        font-size: 16px;
        line-height: 150%;
      }
    }
    &-buttons {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      @include mdq(xs-md) {
        justify-content: flex-start;
      }
      margin-left: 45px;
      @include mdq(xs-md) {
        margin-top: 20px;
        margin-left: 0;
      }
      button {
        margin-left: 30px;
        color: #fff;
        border-bottom: 1px solid #fff !important;
        background: transparent !important;
        font-size: 16px;
        line-height: 150%;
        @include mdq(xs-md) {
          margin-left: 40px;
          font-size: 16px;
          line-height: 150%;
        }
      }
      a {
        color: #fff;
        background: transparent !important;
        border-bottom: none;
        font-size: 16px;
        line-height: 150%;
        @include mdq(xs-md) {
          font-size: 16px;
          line-height: 150%;
        }
      }
    }
  }
}

.header-latest-project {
  .is-filter {
    display: block !important;
    @include mdq(xs-md) {
      display: none !important;
    }
  }
}
.main-latest-project {
  padding: 220px 0 80px 0;
  @include mdq(xs-md) {
    padding: 80px 0 40px 0;
  }
  .latest-project-component {
    .latest-project-empty{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 300px 0;
      @include mdq(xs-md) {
        padding:150px 0;
      }
      p{
        font-size: 32px;
        color: $color-dark;
        @include sohne-leicht;
        line-height: 1.5;
        @include mdq(xs-md) {
          font-size: 22px;
        }
      }
    }
    .mb-control {
      margin-bottom: 80px;
      @include mdq(xs-md) {
        margin-bottom: 40px;
      }
    }
    &-mobile-filter {
      display: none;
      opacity: 0;
      visibility: hidden;
      margin: 30px 0 40px 0;
      @include mdq(xs-md) {
        display: block;
        visibility: visible;
        opacity: 1;
      }
      .header-contain-item-filter {
        display: flex;
        justify-content: flex-start;
        @include mdq(lg) {
          justify-content: center;
        }
        .header-contain-item-nav {
          width: 50%;
          padding-left: 15px;
          ul {
            position: relative;
            display: flex;
            align-items: center;
            li {
              list-style: none;

              svg {
                transition: all 0.5s ease-in;
              }
              a {
                white-space: nowrap;
                @include sohne-buch;
                font-size: 16px;
                line-height: 18px;
                color: $color-dark;
                transition: all 0.5s ease-in;
                display: flex;
                align-items: flex-end;
                svg {
                  margin-left: 7px;
                  margin-bottom: 2px;
                }
              }

              ul {
                position: absolute;
                z-index: 5;
                top: calc(100% - 5px);
                display: flex;
                min-width: 100%;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 10px;
                border-radius: 10px;
                opacity: 0;
                transition: visible 0.4s ease, opacity 0.2s ease;
                visibility: hidden;
                margin-bottom: -16px;

                li {
                  padding: 0;
                  margin-right: 0;
                  width: 100%;
                  list-style: none;
                  margin-bottom: 10px;
                  transition: 0.3s ease all;
                  a {
                    @include sohne-buch;
                    font-size: 16px;
                    line-height: 18px;
                    color: $color-dark !important;
                  }
                }
              }
            }
          }
        }
        .header-contain-item-nav {
          ul {
            li {
              ul li {
                color: $color-dark-grey !important;
                @include sohne-buch;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;
                &:hover {
                  color: $color-dark !important;
                }
                &:focus {
                  color: $color-dark;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-fluid {
  @include img-fluid;
}

@mixin mdq($size) {
  @if $size == xs {
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      @content;
    }
  } @else if $size == md {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $size == lg {
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      @content;
    }
  } @else if $size == xs-md {
    @media only screen and (min-width: 320px) and (max-width: 991px) {
      @content;
    }
  } @else if $size == xs-lg {
    @media only screen and (min-width: 320px) and (max-width: 1200px) {
      @content;
    }
  }
}

// Button Mixins

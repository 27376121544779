.main-contact {
  padding: 160px 0;
  @include mdq(xs-md) {
    padding: 120px 0 80px 0;
  }
}
.contact {
  &-image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include mdq(xs-md) {
      margin-bottom: 20px;
    }
  }
  &-map {
    padding: 0 15px;
    @include mdq(xs-md) {
      margin-bottom: -20px;
      padding: 0;
    }
    iframe {
      height: 770px;
      @include mdq(xs) {
        height: 280px;
      }
      @include mdq(md) {
        height: 450px;
      }
      @include mdq(lg) {
        height: 650px;
      }
    }
  }
  &-item {
    margin-top: 120px;
    &:first-child {
      @include mdq(xs-md) {
        margin-top: 0;
      }
    }
    @include mdq(xs-md) {
      margin-top: 60px;
    }
    &-head {
      @include mdq(xs-md) {
        margin-bottom: 30px;
      }
      h2 {
        font-size: 32px;
        line-height: 36px;
        color: $color-dark;
        @include sohne-leicht;
        @include mdq(xs-md) {
          font-size: 24px;
        }
      }
    }
    &-action {
      &:first-child {
        border-top: 1px solid $color-light-grey;
      }
      &-content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 30px 0;
        border-bottom: 1px solid $color-light-grey;
        @include mdq(xs-md) {
          flex-direction: column;
          gap: 10px;
          padding: 15px 0;
        }
        &:hover {
          p {
            color: $color-dark;
            transform: scale(1.02);
          }
        }
        p {
          font-size: 24px;
          line-height: 150%;
          color: $color-dark-grey;
          @include sohne-leicht;
          transition: all 0.6s ease;
          @include mdq(xs-md) {
            font-size: 16px;
          }

          @include mdq(lg) {
            font-size: 22px;
          }
        }
      }
    }
    &-content {
      a {
        font-size: 24px;
        line-height: 150%;
        color: $color-dark-grey;
        @include sohne-leicht;
        margin-bottom: 50px;
        display: block;
        transition: all 500ms ease !important;
        @include mdq(xs-md) {
          font-size: 20px;
          margin-bottom: 30px;
        }
        &:nth-child(3) {
          text-decoration: underline !important;
        }
        &:hover {
          color: $color-dark;
        }
      }
      &-social {
        &-head {
          margin-bottom: 20px;
          @include mdq(xs-md) {
            margin-bottom: 15px;
          }
          h5 {
            font-size: 24px;
            line-height: 150%;
            color: $color-dark-grey;
            @include sohne-leicht;
            @include mdq(xs-md) {
              font-szie: 20px;
            }
          }
        }
        &-media {
          display: flex;
          a {
            margin-bottom: 0;
            svg circle,
            svg path {
              transition: all 0.5s ease;
            }
            &:not(:first-child) {
              margin-left: 15px;
            }
            &:hover {
              i svg {
                circle {
                  fill: #fff;
                }
                path {
                  fill: $color-dark;
                }
              }
            }
          }
        }
      }
    }
  }
}
